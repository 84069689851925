import React from "react";
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";
import { LinkText } from "../../templates/LinkText";

const ZEV = () => {
  const { t } = useTranslation();
  return (
    <section className="services-details-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <div className="services-details-desc">
              <h3>{t("Applications - ZEV - Title - 1")}</h3>
              <p>{t("Applications - ZEV - Text - 1")}</p>

              <h3>{t("Applications - ZEV - Title - 2")}</h3>

              <p>{t("Applications - ZEV - Text - 2")}</p>
              <ul>
                <li>{t("Applications - ZEV - List - 1_1")}</li>
                <li>{t("Applications - ZEV - List - 1_2")}</li>
                <li>{t("Applications - ZEV - List - 1_3")}</li>
                <li>{t("Applications - ZEV - List - 1_4")}</li>
                <li>{t("Applications - ZEV - List - 1_5")}</li>
              </ul>

              <h3>{t("Applications - ZEV - Title - 3")}</h3>
              <p>{t("Applications - ZEV - Text - 3")}</p>

              <h3>{t("Applications - ZEV - Title - 4")}</h3>
              <p>{t("Applications - ZEV - Text - 4")}</p>
              <p>{t("Applications - ZEV - Text - 5")}</p>

              <h3>{t("Applications - ZEV - Title - 5")}</h3>
              <p>{t("Applications - ZEV - Text - 6")}</p>
              <ul>
                <li>{t("Applications - ZEV - List - 2_1")}</li>
                <li>{t("Applications - ZEV - List - 2_2")}</li>
                <li>
                  <Trans
                    i18nKey="Applications - ZEV - List - 2_3"
                    components={{
                      link_elcom: (
                        <LinkText
                          to="https://www.strompreis.elcom.admin.ch/"
                          title="Elcom"
                        />
                      ),
                    }}
                  />
                </li>
                <li>
                  <Trans
                    i18nKey="Applications - ZEV - List - 2_4"
                    components={{
                      link_vese: (
                        <LinkText
                          to="https://www.vese.ch/pvtarif/"
                          title="VESE"
                        />
                      ),
                    }}
                  />
                </li>
              </ul>
              <h3>{t("Applications - ZEV - Title - 6")}</h3>
              <p>
                <Trans
                  i18nKey="Applications - ZEV - Text - 7"
                  components={{
                    link_LoadOptimization: (
                      <LinkText
                        to="/LoadOptimization"
                        title="LoadOptimization"
                      />
                    ),
                  }}
                />
              </p>
              <p>
                <Trans
                  i18nKey="Applications - ZEV - Text - 8"
                  components={{
                    link_consulting: (
                      <LinkText to="/consulting" title="consulting" />
                    ),
                    link_projectmanagement: (
                      <LinkText
                        to="/projectmanagement"
                        title="projectmanagement"
                      />
                    ),
                    link_meterbilling: (
                      <LinkText to="/meterbilling" title="meterbilling" />
                    ),
                  }}
                />
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ZEV;
